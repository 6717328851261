<template>
    <div class="mb-5">
        <LoadingIcon :isLoading="isLoading" />
        <v-container v-show="!onEdit">
            <v-row no-gutters>
                <v-col cols="12 display-1 header">
                   {{carTitle}}
                   <img class="icon edit-icon" :src="editIcon" @click="onEdit = true"  />
                </v-col>
                <v-col cols="12">
                   <div>{{carSubInfo}}</div>
                </v-col>
            </v-row>
            <v-row class="info-cards mb-0">
                <v-col cols="6" class="py-0">
                <v-card
                        class="mx-auto primary rounded-lg darken-2"
                        height="80">
                        <v-card-text class="pb-0 pt-2 px-3 header-title font-weight-light">
                            Kenteken:
                        </v-card-text>
                        <v-card-actions>
                            <div class="header-title pb-2 px-1 mt-n2 text-start">
                                <span class="font-weight-light">
                                    {{vehicle.licensePlate}}
                                </span>
                            </div>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="6" class="py-0">
                    <v-card
                        class="mx-auto primary rounded-lg darken-2"
                        height="80">
                        <v-card-text class="pb-0 pt-2 px-3 header-title font-weight-light">
                            Meegereden:
                        </v-card-text>
                        <v-card-actions>
                            <div class="header-title pb-2 px-1 mt-n2 text-start">
                                <span class="font-weight-light">
                                    {{vehicle.participatedDistance}} km
                                </span>
                            </div>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="py-0">
            <v-row no-gutters>
                <CarForm 
                    :index="0"
                    :licensePlate="vehicle.licensePlate"
                    :vehicleType="vehicle.vehicleType"
                    :make="vehicle.make"
                    :model="vehicle.model"
                    :year="vehicle.year"
                    :engineCapacity="vehicle.engineCapacity"
                    :cylinders="vehicle.cylinders"
                    :power="vehicle.power"
                    :fuelType="vehicle.fuelType"
                    :id="vehicle.id"
                    :isActive="onEdit"
                    :isNew="false"
                    @update="updateCar"
                    @cancel="cancel" />
            </v-row>
        </v-container>
        <template v-for="(yearEvent, index) in roadtripsPerYear" >
            <div :key="index">
                <v-container>
                    <v-row>
                        <v-col class="col-12 display-1 pa-3">{{yearEvent.year}}</v-col>
                    </v-row>
                </v-container>
                <template v-for="(event, event_index) in yearEvent.events" >
                    <div class="" :key="event_index">
                        <v-container class="py-1 px-0" >
                            <ImageRedirect :imageSource="event.img" :imgClass="'galleryImg'" />
                        </v-container>
                    </div>
                </template>
            </div>
        </template>
      </div>
</template>

<script>
import DataMixin from "@/mixins/data.mixin";
import FormatMixin from "@/mixins/format.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import ImageRedirect from '@/components/common/ImageRedirect'
import LoadingIcon from '@/components/common/LoadingIcon';
import CarForm from "@/components/dashboard/CarForm";

export default {
    name: 'Voertuig',
    components: 
    {
        CarForm,
        LoadingIcon,
        ImageRedirect
    },
    mixins: [DataMixin, MenuHelperMixin, FormatMixin],
    data: () => ({
        deleteIcon: require('@/assets/icons/delete.svg'),
        editIcon: require('@/assets/icons/edit.svg'),
        vehicleId: '',
        vehicle: {
            vehicleType: 0,
            licensePlate: "",
            make: "",
            model: "",
            year: "",
            engineCapacity: 0,
            cylinders: 0,
            fuelType: 0,
            power: 0,
            participatedTrips: 0,
            participatedDistance: 0
        },
        onEdit: false,
        isLoading: false,
        roadtripsPerYear: [
            {
                year: "2018",
                events: [
                    { 
                        img: require('@/assets/album/Winter Roadtrip (2 van 36).jpg'),
                    },
                    { 
                        img: require('@/assets/album/Winter Roadtrip (18 van 36).jpg'),
                    }
                ]
            },
            {
                year: "2017",
                events: [
                    { 
                        img: require('@/assets/album/Winter Roadtrip (1 van 36).jpg'),
                    },
                ]
            }
        ]
    }),
    beforeCreate(){
        this.$store.dispatch('menuHelper/getVehicleOptions');
    },
    mounted () 
    {
        this.$store.commit('setFooterVisibility', true);
        this.vehicleId = this.$route.params.carId;
        this.getVehicleInformation();
    },
    computed:
    {
        carTitle(){
            if(this.vehicle.year == '') return "";
            return `${this.vehicle.make} ${this.vehicle.model} (${this.getYearOnly(this.vehicle.year)})`; 
        },
        carSubInfo() {
            let electricInfo = this.isElectricCar(this.vehicle.fuelType) ? "" : `${this.vehicle.engineCapacity} | ${this.vehicle.cylinders} cilinder | `;
            return `${electricInfo}${this.getFuelType(this.vehicle.fuelType).replace('_', ' ')} | ${this.vehicle.power} pk`; 
        }
    },
    methods: 
    {
        getVehicleInformation()
        {
            this.isLoading = true;
            this.$store.dispatch('vehicleModule/getVehicle', this.vehicleId)
            .then(data => {
                this.vehicle = data;
                this.isLoading = false;
            })
            .catch(() =>
            {
                this.isLoading = false;
            });
        },
        updateCar(index, request)
        { 
            this.onEdit = false;
            this.isLoading = true;
            request.participatedTrips = 0;
            request.participatedDistance = 0;

            this.$store.dispatch('vehicleModule/updateVehicle', request)
            .then(() => {
                this.$store.commit('dialog/showDialog', `Updated vehicle.`);
                this.updateInfo(request);
                this.isLoading = false;
            })
            .catch(() => 
            {
                this.$store.commit('dialog/showDialog', `Something went wrong with updating vehicle.`);
                this.isLoading = false;
            });
        },
        updateInfo(params)
        {
            this.vehicle.vehicleType = params.vehicleType;
            this.vehicle.licensePlate = params.licensePlate;
            this.vehicle.make = params.make;
            this.vehicle.model = params.model;
            this.vehicle.year = params.year;
            this.vehicle.engineCapacity = params.engineCapacity;
            this.vehicle.cylinders = params.cylinders;
            this.vehicle.power = params.power;
            this.vehicle.fuelType = params.fuelType;
           
        },
        cancel()
        {
            this.onEdit = false;
        }
    }
}
</script>
<style>
.galleryImg
{
    height: 280px;
    margin-top: -10px;
    margin-bottom: 15px;
}

.header .edit-icon {
    width: 20px; 
    height: 20px; 
    cursor: pointer;
    margin-top: 12px;
    margin-left: 10px;
}
</style>